import { createAction, props } from '@ngrx/store';
import { UserReducerInitialState } from '../reducers/user.reducer';

const prefix = '[User]';

export const setStateAction = createAction(
  `${prefix} set state`,
  props<Partial<UserReducerInitialState>>()
);

export const setListAction = createAction(`${prefix} set list`, props<any>());

export const setListQueryAction = createAction(
  `${prefix} set list query`,
  props<{
    page: number;
    size: number;
    sortOrder: string;
    sortField: string;
  }>()
);

export const resetListQueryAction = createAction(
  `${prefix} reset list query`,
  props<any>()
);

export const setSearchAction = createAction(
  `${prefix} set search`,
  props<{
    keyword: string;
  }>()
);

export const setDocAction = createAction(
  `${prefix} set doc`,
  props<{ id: string }>()
);

export const deleteUsersAction = createAction(
  `${prefix} delete users`,
  props<{
    setLoading?: (val: boolean) => void;
    onSuccess?: () => void;
    userIds: string[];
  }>()
);
