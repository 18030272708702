import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserReducerInitialState } from '../reducers/user.reducer';

const getUserReducerState =
  createFeatureSelector<UserReducerInitialState>('user');

export const getUserStore = createSelector(
  getUserReducerState,
  (state) => state
);
