import { createAction, props } from '@ngrx/store';
import { RoleReducerInitialState } from '../reducers/role.reducer';

const prefix = '[Role]';

export const setStateAction = createAction(
  `${prefix} set state`,
  props<Partial<RoleReducerInitialState>>()
);

export const setListAction = createAction(`${prefix} set list`, props<any>());

export const setListQueryAction = createAction(
  `${prefix} set list query`,
  props<{
    page: number;
    size: number;
    sortOrder: string;
    sortField: string;
  }>()
);

export const resetListQueryAction = createAction(
  `${prefix} reset list query`,
  props<any>()
);

export const setSearchAction = createAction(
  `${prefix} set search`,
  props<{
    keyword: string;
  }>()
);

export const setDocAction = createAction(
  `${prefix} set doc`,
  props<{ name: string }>()
);

export const createRoleAction = createAction(
  `${prefix} create`,
  props<{
    setLoading?: (val: boolean) => void;
    onSuccess?: () => void;
    name: string;
    description: string;
    teamMembers: string[];
    permissions: string[];
  }>()
);

export const deleteRoleAction = createAction(
  `${prefix} delete role`,
  props<{
    setLoading?: (val: boolean) => void;
    onSuccess?: () => void;
    name: string;
  }>()
);

export const assignRoleAction = createAction(
  `${prefix} assign role`,
  props<{
    setLoading?: (val: boolean) => void;
    onSuccess?: () => void;
    userIds: string[];
    role: string;
  }>()
);

export const unassignRoleAction = createAction(
  `${prefix} unassign role`,
  props<{
    setLoading?: (val: boolean) => void;
    onSuccess?: () => void;
    userIds: string[];
    role: string;
  }>()
);
