import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoleReducerInitialState } from '../reducers/role.reducer';

const getRoleReducerState =
  createFeatureSelector<RoleReducerInitialState>('role');

export const getRoleStore = createSelector(
  getRoleReducerState,
  (state) => state
);
